import React from 'react';
import { Segment, Button } from 'semantic-ui-react';
import ethHandler from '../eth/ethHandler';

function ConnectPrompt({ setConnectedTrue, setIsOwner, msg }) {

    const connectWeb3 = async () => {
        await ethHandler.connect(async () => {
            // Check user if is owner, if not show error
            let isOwner = await ethHandler.checkIfOwner();
            setConnectedTrue(); // Set as connected
            if (setIsOwner) { setIsOwner(isOwner) }
        })
    }

    return (

        <Segment>
            {msg ? (msg) : (
                <>You need to connect web3 to be able to use administration functions</>
            )}
            <Button
                content="Connect Now"
                onClick={connectWeb3}
                size="mini"
                className="ml-1"
            />
        </Segment>

    )

}

export default ConnectPrompt;