import React from 'react';
import { Message, Form, Modal, Button, Grid, Header } from 'semantic-ui-react'
import ethHandler from '../eth/ethHandler';
import AppContext, { getMockBundleDataState } from '../context/AppContext';
import ConnectPrompt from './ConnectPrompt';

function BuyForm({ setShowBuy }) {

    const basePrice = .25;

    const [mOpen, setMOpen] = React.useState(false);

    const appContext = React.useContext(AppContext);
    const [bundleData] = getMockBundleDataState(appContext);
    const [buyForm, setBuyForm] = React.useState({
        price: .25,
        success: "",
        quantity: 1,
        recPrice: .25,
        error: "",
        quantityError: false,
        priceError: false,
    });

    const [loading, setLoading] = React.useState(false);
    const [isConnected, setIsConnected] = React.useState(ethHandler.isConnected());

    const inputCheck = () => {
        // Don't allow empty
        if (buyForm.price === "" || buyForm.quantity === "") {
            return false;
        }
        return true;
    }

    const updateAmount = (e) => {
        // Allow blank
        if (e.target.value === "") {
            return setBuyForm(state => ({ ...state, quantityError: false, quantity: e.target.value }))
        }
        // Only allow numbers
        if (Number.isNaN(parseInt(e.target.value))) {
            return setBuyForm(state => ({ ...state, quantityError: "Must be a number" }));
        }
        // Must be lower than availableBundles
        // let parsed = parseInt(e.target.value);
        // if (parsed > bundleData.unitsLeft) {
        //     return setBuyForm(state => ({...state, quantityError: "Not enough bundles left!"}));
        // }

        // If bundle > 1, require atleast the minimum price
        if (e.target.value > 1 && (buyForm.price < buyForm.recPrice)) {
            console.log("Quantity too high for less than rec price")
            return setBuyForm(state => ({ ...state, priceError: "Suggested price required for multiple bundles", quantityError: "Must be 1 if less than suggested price" }));
        }

        setBuyForm(state => ({ ...state, priceError: false, quantityError: false, quantity: e.target.value, /*recPrice: newRecPrice,*/ }))
    }

    const updatePrice = (e) => {
        // Allow blank
        if (e.target.value === "") {
            return setBuyForm(state => ({ ...state, priceError: false, price: e.target.value }))
        }
        // Also allow single . entry
        if (e.target.value === ".") {
            return setBuyForm(state => ({ ...state, priceError: false, price: e.target.value }))
        }
        // Only allow numbers
        if (Number.isNaN(parseFloat(e.target.value))) {
            return setBuyForm(state => ({ ...state, priceError: "Must be a number" }));
        }
        // let parsed = parseFloat(e.target.value);

        // Require recommended
        // if (parsed < buyForm.recPrice) {
        //     return setBuyForm(state => ({ ...state, priceError: "Must be atleast rec price", price: e.target.value}))
        // }

        // If bundle > 1, require atleast the minimum price
        if (buyForm.quantity > 1 && (parseFloat(e.target.value) < buyForm.recPrice)) {
            console.log("Quantity too high for less than rec price")
            return setBuyForm(state => ({ ...state, price: e.target.value, priceError: "Suggested price required for multiple bundles", quantityError: "Must be 1 if less than suggested price" }));
        }

        setBuyForm(state => ({ ...state, priceError: false, quantityError: false, price: e.target.value, error: false }))

    }

    const buyBundle = async () => {
        setLoading(true)
        let buy = await ethHandler.buyBundle(bundleData.bundleId, buyForm.price, buyForm.quantity);
        if (buy.error) {
            setBuyForm(s => ({ ...s, error: buy.error.message, success: false }));
            setLoading(false);
        }
        else {
            setBuyForm(s => ({ ...s, success: "You bought the bundle!", error: false }));
            setLoading(false);
        }
    }

    return (


        <Form error={!!buyForm.error} success={!!buyForm.success}>

            <Modal open={mOpen} size="small" dimmer="inverted">
                <Grid padded relaxed>
                    <Grid.Column width={16}>
                        <Header color="orange" className="secondaryFont">Confirm Purchase</Header>
                        <Header as="h4" className="uppercase">You are about to putchase <span style={{color:"rgba(90,90,90,1"}}>{buyForm.quantity} bundle</span> for <span style={{color:"blue"}}>{buyForm.price} Ethereum.</span></Header>
                    </Grid.Column>

                    <Grid.Column width={14} mobile={16}>
                        <Message hidden={!buyForm.error} error>
                            Error: {buyForm.error}
                        </Message>
                    </Grid.Column>

                    <Grid.Column width={16}>
                            <Button size="mini" content="Purchase" color="green" onClick={buyBundle} floated="right" loading={loading} disabled={loading}/>
                            <Button size="mini" content="Cancel" color="red" onClick={() => setMOpen(false)} loading={loading} disabled={loading}/>
                    </Grid.Column>
                </Grid>

            </Modal>

            {isConnected ? (<>

                <div style={{maxWidth: "340px"}}>

                    <Form.Input
                        value={buyForm.quantity}
                        onChange={e => updateAmount(e)}
                        placeholder="Number of Bundles To Purchase"
                        label="Quantity"
                        error={buyForm.quantityError}
                    />

                    <Form.Input
                        value={buyForm.price}
                        onChange={e => updatePrice(e)}
                        label={"Suggested Price: " + buyForm.recPrice}
                        error={buyForm.priceError}
                        placeholder={`Reccommended: ${basePrice} per bundle`}
                    />

                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }} className="mt-3">
                    <Form.Button content="BACK" inverted color="orange" size="big" className="secondaryFont" onClick={() => setShowBuy(false)} />
                    <Form.Button content="BUY" color="green" size="big" className="secondaryFont ml-2"
                        disabled={!!buyForm.error || !!buyForm.priceError || !!buyForm.quantityError || !inputCheck()}
                        onClick={() => setMOpen(true)}
                        loading={loading}
                    />
                </div>

            </>) : <ConnectPrompt msg="You need Web3 Connected to purchase!" setConnectedTrue={() => setIsConnected(true)} />}


            <Message error content={buyForm.error} />
            <Message success content={buyForm.success} />

        </Form>

    )

}

export default BuyForm;