import React from 'react';
import { Grid, Header, Message } from 'semantic-ui-react'

function Notes() {

    const [hide, setHide] = React.useState(false);

    return hide ? null : (
        <Grid.Column width={16}>

            <Message onDismiss={() => setHide(true)}>
                <Header sub> notes </Header> <br />
                    Be aware, while testing, we are branching off of the mainnet via a hardhat fork.
                    This will give us the closest testing model to a production deployment.
                    We can set the blocknumber to match where the fork starts to allow a full dry run on a cloned mainnet fork

                <br />By default bundle creation utlizes the rarible1155 contract and token ids should be for tokenIds relative to that contract.

                <br /><br />
                Upon starting the fork, the 5 token ids as entered in the id field will be minted to the contract address so that it has ownership of them

                <br /><br />
                In practice artists will send their tokens to the smart contract to be bundled by us and put up for sale.

                <br/><br/>
                <Header sub color="green">Testing Instructions</Header>
                1 - Start Fork , be patient will print 'READY FOR STEP 2' when ready for step 2
                <br />2 - Ready signifies that the contract owns the tokens and is ready to bundle them -- You can verify this with the token balancer below
                <br />3 - Set the start/end dates and create the bundle with with remaining default values
                <br />4 - Go to the main page and buy the tokens
                <br />5 - After purchasing come back here and check the token id balances for the purchasing address

            </Message>

        </Grid.Column>
    )

}

export default Notes;