import React from 'react';
import { Card, Grid, Header, Image, Table } from 'semantic-ui-react';
import AppContext, { getMockBundleDataState } from '../context/AppContext';

/**
 * @prop {Object} bundleData - Bundle Data object from appState context 
 */
function BundlePoster({ showHeader }) {

    const appContext = React.useContext(AppContext);
    const [bundleData] = getMockBundleDataState(appContext);

    React.useEffect(() => {
        let video = document.getElementById('vid');
        if (video) {
            video.play();
        }
    }, [])

    if (!bundleData) {
        return <Header className="mt-3" textAlign="center">No Bundle Available</Header>
    }

    const renderAttributes = () => {
        let rows = bundleData.tokenMeta[0].attributes.map(attrib => {
            if (attrib.key === "twitter") {
                return null;
            }
            else {
                return (<>
                    <Table.Row>
                        <Table.Cell>{attrib.key}</Table.Cell>
                        <Table.Cell>{attrib.value}</Table.Cell>
                    </Table.Row>
                </>)
            }
        }
        )
        return (
            <Table basic="very" compact="very" className="secondaryFont" style={{ fontSize: "11px" }}>
                <Table.Body>
                    {rows.length >= 1 && (rows.length !== 1 && rows[0] !== null) ? rows : (
                        <Table.Row>
                            <Table.Cell>No Attributes</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        )
    }

    // Prioritize video
    const cardContent = !!bundleData.posterAnim ? (
        <video controls autoPlay muted className="cEmbed">
            <source src={bundleData.posterAnim} type="video/mp4" />
        </video>
    ) : <Image fluid centered src={bundleData.posterImg} className="image-box-styling" />

    return (
        <Grid >

            <Grid.Column width={8} tablet={16}>
                <Header sub>
                    Incoherents Club
                </Header>
                Incoherents Club is an NFT marketplace focused on selling limited edition bundles of curated NFTs at a “pay what you want price”.

                Each bundle is curated by guest curators, supplemented by a unique digital poster from a selected artist. Buyers can choose their own price, with all first sales revenue split between creators, curator, charity, and Incoherents Club.

                Incoherents Club is an experimental platform intended to be governed and eventually owned by its users and workers using a Colony DAO.
            </Grid.Column>

            <Grid.Column width={8} tablet={16}>
                <Header sub>
                    Pay What You Want
                </Header>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Grid.Column>

            <Grid.Column width={8} tablet={16}>
                <Header sub>
                    Support Charity
                </Header>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <Header sub>
                    Where your money goes
                </Header>
                {
`Creators: 65%
Curator: 5%
Charity: 10%
Incoherents Club Tip: 5%
Platform Fees: 15%
`}
            </Grid.Column>


            <Grid.Column width={16}>
                <div className="cEmbedWrap">
                    <Card raised>
                        {cardContent}
                        <Card.Content>
                            <Card.Header style={{ fontSize: "14px" }}>{bundleData.tokenMeta[0].name}</Card.Header>
                        </Card.Content>
                        <Card.Content extra>
                            {renderAttributes()}
                        </Card.Content>
                    </Card>
                </div>
            </Grid.Column>

        </Grid>
    )

}

export default BundlePoster;