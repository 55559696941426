// Import / Export ABIs
const bundleAbi = require('./Bundle.json');
const mintAbi = require('./Mint.json');
const rariAbi = require('./Rarible1155.json');

const abis = {
    bundleAbi: bundleAbi,
    mintAbi: mintAbi,
    rariAbi: rariAbi,
}

export default abis;