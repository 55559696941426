import React from 'react';
import { Grid, Header, Segment, TextArea, Form } from 'semantic-ui-react';
import lstyle from './TestFork.module.scss';
import socketIOClient from 'socket.io-client';
import axios from 'axios';

const socketEndpoint = "https://eth.incoherents.duckdns.org";

function TestFork() {

    const [output, setOutput] = React.useState(""); 
    const [startLoad, setStartLoad] = React.useState(false);
    const [stopLoad, setStopLoad] = React.useState(false);

    const textBoxRef = React.useRef();

    const scrollTxtBox = () => {
        if (textBoxRef && 
            textBoxRef.current && 
            textBoxRef.current.ref && 
            textBoxRef.current.ref.current) {
            textBoxRef.current.ref.current.scrollTop = textBoxRef.current.ref.current.scrollHeight;
        }
    }

    React.useEffect( () => {
        const socket = socketIOClient(socketEndpoint, {transports: ['websocket']});
        
        socket.on("connected", (consoleOutput) => {
            setOutput(consoleOutput);
            scrollTxtBox();
        });

        socket.on("newLog", (log) => {
            setOutput(log);
            scrollTxtBox();
        })

    }, [])

    const startFork = async () => {
        setStartLoad(true);
        let success = await axios.get(socketEndpoint + '/startFork');
        console.log(success);
        setStartLoad(false);
    }

    const stopFork = async () => {
        setStopLoad(true);
        let success = await axios.get(socketEndpoint + '/stopFork');
        console.log(success);
        setStopLoad(false);
    }

    return (

        <Grid.Column width={16}>
            <Segment>


                <Header>Test Fork Toolkit
                    <Header.Subheader>Fork runs on :8545, set MM appropriately.</Header.Subheader>
                </Header>

                <Form>

                    <Form.Group>
                        <Form.Button loading={startLoad} content="Start Fork" size="mini" onClick={startFork}/>
                        <Form.Button loading={stopLoad} content="Stop Fork" size="mini" onClick={stopFork}/>
                    </Form.Group>
    
                    <Header sub>Fork STDOUT</Header>
                    <TextArea className={lstyle.consoleBox} value={output} ref={textBoxRef}/>
    
                </Form>

            </Segment>
        </Grid.Column>


    )

}

export default TestFork;
