import React from 'react';
import { Grid, Header, Button } from 'semantic-ui-react';
import { getBundle } from '../api/api.js';
import BuyForm from './BuyForm';
import CountDownTimer from '@inlightmedia/react-countdown-timer';
import AppContext, { getMockBundleDataState } from '../context/AppContext';
import lstyle from './BuyOptions.module.scss';

function BuyOptions() {

    const appContext = React.useContext(AppContext);
    const [showBuy, setShowBuy] = React.useState(false);
    const [bundleData, setBundleData] = getMockBundleDataState(appContext); //eslint-disable-line

    const start = bundleData ? new Date(bundleData.saleStart * 1000) : false;
    const end = bundleData ? new Date(bundleData.saleEnd * 1000) : false;
    const now = new Date();

    const loadBundleData = async () => {

        let bundleDataRes;

        try {
            bundleDataRes = await getBundle();
        } catch (ex) {
            console.error(ex)
            setBundleData(false);
            return;
        }

        // If no bundle items, set no bundles
        if (bundleDataRes.data.error || !bundleDataRes) {
            setBundleData(false);
            return;
        }

        setBundleData(bundleDataRes.data);

    }

    const soldOut = bundleData.units > bundleData.unitsLeft;

    /*)

const renderAttributes = () => {
    let rows = bundleData.tokenMeta[0].attributes.map(attrib => {
        if (attrib.key === "twitter") {
            return null;
        }
        else {
            return (<>
                <Table.Row>
                    <Table.Cell>{attrib.key}</Table.Cell>
                    <Table.Cell>{attrib.value}</Table.Cell>
                </Table.Row>
            </>)
        }
    }
    )
    return (
        <Table basic="very" compact="very" className="secondaryFont">
            <Table.Body>
                {rows.length >= 1 && (rows.length !== 1 && rows[0] !== null) ? rows : (
                    <Table.Row>
                        <Table.Cell>No Attributes</Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    )
}


    */

    const getRemainingText = () => {
        const remaining = parseInt(bundleData.unitsLeft);
        if (remaining === 1) {
            return <span as="h5" className="uppercase">Final Bundle of {bundleData.units}</span>;
        }
        else if (remaining > 1) {
            return <span as="h5" className="uppercase">{bundleData.unitsLeft} of {bundleData.units} bundles left</span>;
        }
        else {
            return <span as="h5" className="uppercase" color="orange">All out of bundles!</span>
        }
    }

    /*
    const getTwitter = () => {
        let twitAttrib = bundleData.tokenMeta[0].attributes.filter(k => (k.key === "twitter"));
        if (twitAttrib[0]) {
            return <a href={"https://twitter.com/" + twitAttrib[0].value} rel="noreferrer" target="_blank">
                @{twitAttrib[0].value}
            </a>
        }
    }
    */

    const getTwitterUrl = () => {
        let twitAttrib = bundleData.tokenMeta[0].attributes.filter(k => (k.key === "twitter"));
        if (twitAttrib[0]) {
            return "https://twitter.com/" + twitAttrib[0].value;
        }
    }

    if (start > now) {
        return (<>
            <Grid.Column computer={5} mobile={16}>
                <Header className="primaryFont">
                    Sorry, but this sale hasn't started!
                        <Header.Subheader>
                        Tune in later to grab a bundle!
                        </Header.Subheader>
                </Header>
            </Grid.Column>
            <Grid.Column computer={11} mobile={16} textAlign="center">
                <Header className="primaryFont mt-2">
                    BUYING BEGINS IN
                    </Header>
                <Header className="primaryFont">
                    <Button color="grey" size="big" disabled content={<>
                        <CountDownTimer style={{ color: "white" }} dateTime={start.toISOString()} onCountdownCompletion={loadBundleData()} />
                    </>} />
                </Header>
            </Grid.Column>
        </>)
    }
    else if (!bundleData) {
        return null;
    }
    else if (now > end) {
        return (
            "Sorry this sale ended"
        )
    }
    else {
        return (<Grid className={lstyle.buyOptGrid} style={{ height: "auto" }}>

            <Grid.Column width={16} verticalAlign="top">
                <Header as="h1" className="secondaryFont uppercase">
                    Bundle #{bundleData.bundleId}
                    <Header.Subheader>
                        Curator: <a href={getTwitterUrl()} rel="noreferrer" target="_blank">@NotAPerson</a>
                        <br />
                        <span style={{ fontSize: "11px" }}>
                            {getRemainingText()}
                        </span>
                    </Header.Subheader>
                </Header>
            </Grid.Column>

            <Grid.Column largeScreen={16}>
                {showBuy ? (
                    <div className="secondaryFont" style={{ textAlign: "left" }}>
                        Select Bundle Quantity and Purchase Price
                    </div>
                ) : (
                    <div className="secondaryFont" style={{ textAlign: "left", whiteSpace: "pre-wrap" }}>
                        <Header sub>
                            About This Bundle
                        </Header>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

                        <Header sub>
                            Charity
                        </Header>

                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

                    </div>
                )}
            </Grid.Column>

            <Grid.Column computer={16} verticalAlign="bottom" textAlign="left" style={{ paddingBottom: ".25rem" }}>
                {showBuy ? <BuyForm setShowBuy={setShowBuy} /> : (
                    <Button color={soldOut ? "red" : "green"} disabled={soldOut} content={soldOut ? "SOLD OUT" : "BUY NOW"} size="large" onClick={() => setShowBuy(true)} fluid style={{ maxWidth: "320px" }} />
                )}
            </Grid.Column>

        </Grid >)
    }

}

export default BuyOptions;