import { Grid, Icon, Header } from 'semantic-ui-react';

function SocialLinks() {

    const colStyles = {
        padding: ".5rem"
    }

    const linkstyle = { color: "black" }

    return (
        <Grid padded columns={5} textAlign="center" verticalAlign="middle" >

            <Grid.Column tablet={8} mobile={16} only="tablet mobile">
                <Header>Incoherents Club
                    <Header.Subheader>Pay what you want NFT bundles</Header.Subheader>
                </Header>
            </Grid.Column>

            <Grid.Column style={colStyles}>
                <a href="https://instagram.com/incoherentsclub" target="_blank" rel="noreferrer" style={linkstyle}>
                    <Icon name="instagram" size="large" className="hover-tilt hover-accent" />
                </a>
            </Grid.Column>

            <Grid.Column style={colStyles}>
                <a href="https://twitter.com/incoherentsclub" target="_blank" rel="noreferrer" style={linkstyle}>
                    <Icon name="twitter" size="large" className="hover-tilt hover-accent" />
                </a>
            </Grid.Column>

            <Grid.Column style={colStyles}>
                <a href="https://discord.gg/TmMJ3DHzKA" target="_blank" rel="noreferrer" style={linkstyle}>
                    <Icon name="discord" size="large" className="hover-tilt hover-accent" />
                </a>
            </Grid.Column>

            <Grid.Column style={colStyles} verticalAlign="middle">
                <a href="https://incoherentsclub.substack.com" target="_blank" rel="noreferrer" style={linkstyle}>
                    <svg className={"hover-tilt hover-accent"} width="1.25em" height="1.25em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M22.539 8.242H1.46V5.406h21.08v2.836zM1.46 10.812V24L12 18.11L22.54 24V10.812H1.46zM22.54 0H1.46v2.836h21.08V0z" fill="#000000" /></svg>
                </a>
            </Grid.Column>

        </Grid>
    )

}

export default SocialLinks;