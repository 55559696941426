import React from 'react';
import { getBundle } from '../api/api.js';
import { Grid, Loader, Header, Segment } from 'semantic-ui-react'

import AppContext, { getMockBundleDataState } from '../context/AppContext';
import lstyle from './Lander.module.scss';

/* Components */
import Logo from '../components/Logo';
import BundlePoster from '../components/BundlePoster';
import BuyOptions from '../components/BuyOptions';
import SocialLinks from '../components/SocialLinks';
import BundleItems from '../components/BundleItems';

import ethHandler from '../eth/ethHandler';

function Lander() {

    const appContext = React.useContext(AppContext);
    const [bundleData, setBundleData] = getMockBundleDataState(appContext); //eslint-disable-line

    const [isConnected] = React.useState(ethHandler.isConnected());
    const [loading, setLoading] = React.useState(true);

    // Load bundle data
    React.useEffect(() => {

        const loadBundleData = async () => {
            setLoading(true);

            let bundleDataRes;

            try {
                bundleDataRes = await getBundle();
            } catch (ex) {
                console.error(ex)
                setLoading(false);
                setBundleData(false);
                return;
            }

            // If no bundle items, set no bundles
            if (bundleDataRes.data.error || !bundleDataRes) {
                setLoading(false);
                setBundleData(false);
                console.log('hit')
                return;
            }

            setLoading(false);
            setBundleData(bundleDataRes.data);

        }

        loadBundleData();

        // eslint-disable-next-line
    }, [isConnected])

    return (

        <Grid padded>


            <Grid.Column computer={8} only="computer" verticalAlign="middle">
                <Logo />
            </Grid.Column>

            <Grid.Column computer={8} tablet={16} verticalAlign="middle">
                <SocialLinks />
            </Grid.Column>

            {loading ? (
                <Segment basic placeholder style={{ minHeight: "600px" }} textAlign="center">
                    <Loader active={loading} content="Fetching Bundle..." />
                </Segment>
            ) : (<>

                <Grid.Column computer={bundleData ? 8 : 16} tablet={16} >
                    <BundlePoster />
                </Grid.Column>

                <Grid.Column computer={bundleData ? 8 : 16} tablet={16} className={lstyle.buyOptsCol} verticalAlign="middle">
                    <BuyOptions />
                </Grid.Column>

                <Grid.Column computer={16} mobile={16} textAlign="center" verticalAlign="middle">

                    <Grid divided="vertically">

                        {bundleData ? (
                            <Grid.Row verticalAlign="middle">
                                <Grid.Column width={16} verticalAlign="middle mb-0" >
                                    <Header as="h3" textAlign="left" className="secondaryFont uppercase"> Included In This Bundle</Header>
                                </Grid.Column>
                            </Grid.Row>
                        ) : null}

                        <Grid.Row>
                            <Grid.Column width={16}>
                                <BundleItems />
                            </Grid.Column>
                        </Grid.Row>


                    </Grid>

                </Grid.Column>

            </>)}


        </Grid >


    )

}

export default Lander;