import Web3 from 'web3'
import Web3Modal from 'web3modal';
import contractMethods from './contractMethods';
import { isProduction } from '../util/generic';
import { contractNameToContractAddress, CONTRACTNAMES } from '../config/contracts';
import abis from '../abi/abiIndex';

class EthHandler {

    constructor() {
        this.accounts = [];
        this.ethNetwork = false;
        this.web3 = false;
        this.web3Modal = false;
        this.provider = false;

        this.contracts = {}

        this._setEnvironment('dev', 'mainnet');
        this._initWeb3Modal();

    }

    /* Private */

    _constructError(msg) {
        return {
            error: true,
            message: msg,
        }
    }

    _getAbi(contractName) {
        switch (contractName) {
            case CONTRACTNAMES.INCOHERENTS: return abis.bundleAbi;
            case CONTRACTNAMES.MOCKER: return abis.mintAbi;
            case CONTRACTNAMES.RARI_1155: return abis.rariAbi;
            default: throw Error("Trying to fetch nonexistant contract abi");
        }
    }

    async _getAccount() {
        return !this.accounts ? await this._loadAccounts()[0] : this.accounts[0]
    }

    _getContract(contractName) {
        try {
            if (this.contracts[contractName]) { return this.contracts[contractName] };
            let abi = this._getAbi(contractName);
            let contractAddress = contractNameToContractAddress(contractName);
            let contract = new this.web3.eth.Contract(abi, contractAddress);
            this.contracts[contractName] = contract;
            return contract;
        } catch (ex) {
            console.error("Error loading contract, is web3 available? Refresh the page and try again", ex);
        }
    }

    _haveAccounts() {
        return this.accounts.length > 0;
    }

    _initWeb3Modal() {
        this.web3Modal = new Web3Modal({
            network: this.ethNetwork, // optional
        });
    }

    async _loadAccounts() {
        let acc = await this.web3.eth.getAccounts();
        this.accounts = acc;
        return acc;
    }

    _setEnvironment(overrideEnv, overrideNetw) {
        if (overrideEnv && overrideNetw) {
            this.env = overrideEnv;
            this.ethNetwork = overrideNetw;
        } else {
            let isProd = isProduction();
            this.environment = isProd ? 'prod' : 'alpha';
            this.ethNetwork = isProd ? 'mainnet' : 'goerli';
        }
    }

    /* Public */

    /**
     * Attempts to connect a web3 wallet via web3modal
     * Accepts 2 callbacks, one for succes, and one for error
     * @param { Function } cb - Callback on web3 connect success
     * @param { Fuction } erCb - Callback on web3 connect failure
     */
    async connect(cb = () => { return null }, erCb = () => { return null }) {

        if (window.ethereum) {
            try {
                this.provider = await this.web3Modal.connect();
                this.web3 = new Web3(this.provider);
                await this._loadAccounts();
                if (cb) { cb() }
                return { error: false };
            } catch (error) {
                console.error(error);
                if (erCb) { erCb() }
                if (error && error.code === 4001) {
                    return this._constructError("User rejected request");
                }
            }
        }

        else {
            return this._constructError("No Web3 Wallet Detected!");
        }
    }

    getWeb3() {
        if (!this.web3) {
            return console.error("Web3 not available yet! This shouldn't happen.")
        }
        return this.web3;
    }

    getAccount() {
        return this.accounts ? this.accounts[0] : false;
    }

    isConnected() {
        return this.web3 !== false;
    }

    async sign(msg) {

        if (!this.isConnected()) {
            await this.connect();
        }

        try {
            let signature = await this.web3.eth.personal.sign(msg, this.accounts[0]);
            return [signature, this.accounts[0]];
        } catch (ex) {
            console.log(ex)
            return [{ error: ex.message }, null]
        }

    }

    //////////////////////////////////
    /* Contract Method Passthroughs */
    //////////////////////////////////

    // Check if user is the Bundles contract owner
    async checkIfOwner() {
        let address = await this._getAccount();
        return await contractMethods.checkIfOwner(this._getContract(CONTRACTNAMES.INCOHERENTS), address);
    }

    async mintTestToken(toAddress, tokenId, tokenValue, tokenData) {
        let address = await this._getAccount();
        return await contractMethods.mintTestToken(this._getContract(CONTRACTNAMES.MOCKER), address, toAddress, tokenId, tokenValue, tokenData);
    }

    async mintAll5(toAddress) {
        let address = await this._getAccount();
        return await contractMethods.mintAll5(this._getContract(CONTRACTNAMES.MOCKER), address, toAddress);
    }

    async getRari1155URI(tokenId) {
        let address = await this._getAccount();
        return await contractMethods.getTestTokenURI(this._getContract(CONTRACTNAMES.RARI_1155), address, tokenId);
    }

    async getTestTokenURI(tokenId) {
        let address = await this._getAccount();
        return await contractMethods.getTestTokenURI(this._getContract(CONTRACTNAMES.MOCKER), address, tokenId);
    }

    async getTestTokenBal(tokenId) {
        let address = await this._getAccount();
        return await contractMethods.getTestTokenBal(this._getContract(CONTRACTNAMES.MOCKER), address, tokenId);
    }

    async getRari1155Bal(address, tokenId) {
        address = this.web3.utils.toChecksumAddress(address)
        return await contractMethods.getRari1155Bal(this._getContract(CONTRACTNAMES.RARI_1155), address, tokenId);
    }

    async getTestTokenBalBatch(tokenIds) {
        let address = await this._getAccount();
        return await contractMethods.getTestTokenBalBatch(this._getContract(CONTRACTNAMES.MOCKER), address, tokenIds);
    }

    async createBundle(artistPayable, saleStart, saleEnd, units, weiMin, nftCAddress, tokenIds) {
        let address = await this._getAccount();
        return await contractMethods.createBundle(this._getContract(CONTRACTNAMES.INCOHERENTS),
            address, artistPayable, saleStart, saleEnd, units, weiMin, nftCAddress, tokenIds
        );
    }

    async buyBundle(bundleId, priceInEth, quantity) {
        let address = await this._getAccount();
        let priceInWei = this.web3.utils.toWei(String(priceInEth), 'ether')
        return await contractMethods.buyBundle(this._getContract(CONTRACTNAMES.INCOHERENTS), address, bundleId, quantity, priceInWei); // Buy 1 bundle
    }

    async getActiveBundleData() {
        return await contractMethods.getActiveBundleData(this._getContract(CONTRACTNAMES.INCOHERENTS));
    }

    async getActiveBundleItems() {
        return await contractMethods.getActiveBundleItems(this._getContract(CONTRACTNAMES.INCOHERENTS));
    }

    /*
                mbs.artistPayableAddress,
                mbs.saleStart, mbs.saleEnd, mbs.units, weiMin,
                mbs.nftContractAddress, mbs.tokenIds.join(" ")
    */

}

const ethHandler = new EthHandler();
export default ethHandler;