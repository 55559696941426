const checkIfOwner = async (contract, callee) => {
    return await _tryMethod(async () => {
        let owner = await contract.methods.admin().call();
        return owner === callee;
    })
}

/* Mint a test token to the toAddress */
const mintTestToken = async (contract, callee, toAddress, tokenId, tokenValue, tokenData) => {
    return await _tryMethod(async () => {
        let res = await contract.methods.mint(toAddress, String(tokenId), tokenValue, tokenData).send({
            from: callee,
        });
        return res;
    })
}

const mintAll5 = async (contract, callee, toAddress) => {
    return await _tryMethod(async () => {
        let res = await contract.methods.mintBatch(toAddress, ["1", "2", "3", "4", "5"], ["1", "1", "1", "1", "1"], "0x0").send({
            from: callee,
        });
        return res;
    })
}

/* Get Rarible1155 Metadata URI */
const getRari1155URI = async (contract, callee, tokenId) => {
    return await _tryMethod(async () => {
        return await contract.methods.uri(tokenId).call();
    })
}

/* Get the URI for metadata associated with the test ERC 1155 */
const getTestTokenURI = async (contract, callee, tokenId) => {
    return await _tryMethod(async () => {
        return await contract.methods.uri(tokenId).call();
    })
}

const getRari1155Bal = async (contract, address, tokenId) => {
    return await _tryMethod(async () => {
        return await contract.methods.balanceOf(address, tokenId).call();
    })
}

/* Get the test token balance of a specific test token ID */
const getTestTokenBal = async (contract, callee, id) => {
    return await _tryMethod(async () => {
        return await contract.methods.balanceOf(callee, id).call();
    })
}

/* Get the test token balance of a batch of 5 ids */
const getTestTokenBalBatch = async (contract, callee, ids) => {
    return await _tryMethod(async () => {
        // Callee 5x here to get each id balance for the same address
        return await contract.methods.balanceOfBatch([callee, callee, callee, callee, callee], ids).call();
    })
}

/* Create a new bundle */
const createBundle = async (contract, callee, artistPayable, saleStart, saleEnd, units, weiMin, nftCAddress, tokenIds) => {
    console.log({
        contract: contract,
        artistPayable: artistPayable,
        saleStart: saleStart,
        saleEnd: saleEnd,
        units: units,
        weiMin: weiMin,
        nftCAddress: nftCAddress,
        tokenIds: tokenIds
    })
    return await _tryMethod(async () => {
        return await contract.methods.createBundle(
            artistPayable, saleStart, saleEnd, units, weiMin, nftCAddress, tokenIds
        ).send({ from: callee });
    })
}

const getActiveBundleData = async (contract) => {
    return await _tryMethod(async () => {
        let activeBundle = await contract.methods.bundleId().call();
        if (activeBundle === "0" ) { return false }
        let bundle = await contract.methods.bundle(activeBundle).call();
        bundle.bundleId = activeBundle;
        return bundle;
    })
}

const buyBundle = async (contract, callee, bundleId, quantity, priceInWei) => {
    console.log(priceInWei)
    return await _tryMethod(async () => {
        let buyBundle = await contract.methods.buyBundle(bundleId, quantity).send({from: callee, value: priceInWei});
        return buyBundle;
    })
}


/* Get active bundle items */
const getActiveBundleItems = async (contract) => {
    return await _tryMethod(async () => {
        let activeBundle = await contract.methods.bundleId().call();
        if (activeBundle === "0" ) { return false }
        let bundle = await contract.methods.viewBundleItems(activeBundle).call();
        return bundle;
    })
}

/* Try Method Wrapper for Error Handling */
const _tryMethod = async (wrappedCall = async () => { return null }) => {
    try {
        return await wrappedCall();
    } catch (ex) {
        console.error(ex)
        return { error: ex }
    }
}

const methods = {
    buyBundle: buyBundle,
    createBundle: createBundle,
    checkIfOwner: checkIfOwner,
    mintTestToken: mintTestToken,
    mintAll5: mintAll5,
    getRari1155URI: getRari1155URI,
    getRari1155Bal: getRari1155Bal,
    getActiveBundleData: getActiveBundleData,
    getActiveBundleItems: getActiveBundleItems,
    getTestTokenURI: getTestTokenURI,
    getTestTokenBal: getTestTokenBal,
    getTestTokenBalBatch: getTestTokenBalBatch,
}

export default methods;