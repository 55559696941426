import React from 'react';
import { Container } from 'semantic-ui-react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import AppContext, { defaultContext } from './context/AppContext';
import Lander from './pages/Lander';
import Admin from './pages/Admin';
import Footer from './components/Footer.jsx';

function App() {

  const [appState, setAppState] = React.useState(defaultContext)
  const appValue = { state: appState, setState: setAppState };

  return (

    <AppContext.Provider value={appValue}>

      <Container fluid>

        <Router>

          <Route exact path="/" component={Lander} />
          <Route exact path="/admin" component={Admin} />

        </Router>

        <Footer/>

      </Container>

    </AppContext.Provider>
  );
}

export default App;
