import React from 'react';
import { Grid, Header, Segment, Form, Message, Label } from 'semantic-ui-react';
import ethHandler from '../../eth/ethHandler';
import lstyle from '../Admin.module.scss';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

function MakeBundle() {

    const web3 = ethHandler.getWeb3();

    const [isOpen, setIsOpen] = React.useState(true);
    const [makeBundleState, setMakeBundleState] = React.useState({
        artistPayableAddress: "0x8490bb9271E110923ec3c8ca079895ed60312f0D",
        saleStart: "",
        saleEnd: "",
        units: "1",
        minCost: ".1",
        nftContractAddress: process.env.REACT_APP_CONTRACT_RARIBLE_1155,
        tokenIds: "503911 154155 282035 253703 194720",
        hourAdd: 0,
    });
    const updateMakeBundleState = (update) => setMakeBundleState(s => ({ ...s, ...update }));
    const updateMakeBundleStateKV = (k, v) => setMakeBundleState(s => ({ ...s, [k]: v }))

    console.log(makeBundleState);

    const createBundle = async () => {
        let mbs = makeBundleState;
        // Convert min cost to wei
        let weiMin = web3.utils.toWei(mbs.minCost, 'ether');
        let start = mbs.saleStart + (mbs.hourAdd * 1000 * 60);
        // Always start 5 mins from now for dev. . .
        start = Math.floor( (new Date().getTime()/1000) + (60 * 5));
        let created = await ethHandler.createBundle(
            mbs.artistPayableAddress,
            String(start), String(mbs.saleEnd), mbs.units, weiMin,
            mbs.nftContractAddress, mbs.tokenIds.split(" ")
        )
        console.log(created);
        if (created.error) {
            setMakeBundleState(state => ({ ...state, error: created.error.message, success: false }));
        }
    }

    const updateTime = (key, data) => {
        if (!data || !data.value) { return }
        updateMakeBundleStateKV(key, String(Math.floor(data.value.getTime() / 1000)))
    }

    return (

        <Grid.Column width={16}>

            <Segment onClick={() => setIsOpen(s => !s)} className="hover-accent">
                <Header>
                    Create Bundle
                </Header>
            </Segment>

            {isOpen ? (
                <Segment className={[lstyle.mintPanel, isOpen ? "" : lstyle.closed].join(" ")}>
                    <Header sub color="blue">
                        {process.env.REACT_APP_CONTRACT_INCOHERENTS_DEV}
                    </Header>
                    <br />
                    <Form error={!!makeBundleState.error} success={!!makeBundleState.success}>
                        <Form.Input label="ArtistPayable:" value={makeBundleState.artistPayableAddress} onChange={e => { updateMakeBundleStateKV("artistPayableAddress", e.target.value) }} />

                        <Label>Start Date and Hour (24) -- Fill out but irrelevent for testing will be in 5 minutes!</Label>
                        <Form.Group className="mt-1">
                            <SemanticDatepicker onChange={(event, data) => updateTime("saleStart", data)} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Input
                                placeholder="Hour 0-23"
                                content="Time"
                                value={makeBundleState.hourAdd}
                                onChange={e => setMakeBundleState(s => ({...s, hourAdd: e.target.value}))}
                            />
                        </Form.Group>

                        <Label>End Date (00:00) Midnight Day Before</Label>
                        <Form.Group className="mt-1">
                            <SemanticDatepicker onChange={(event, data) => updateTime("saleEnd", data)} />
                        </Form.Group>

                        <Form.Input label="Units:" value={makeBundleState.units} onChange={e => { updateMakeBundleStateKV("units", e.target.value) }} />
                        <Form.Input label="MinCost:" value={makeBundleState.minCost} onChange={e => { updateMakeBundleStateKV("minCost", e.target.value) }} />
                        <Form.Input label="nftContractAdd: (Default RARIBLES_1155)" value={makeBundleState.nftContractAddress} onChange={e => { updateMakeBundleStateKV("nftContractAddress", e.target.value) }} />
                        <Form.Input label={`tokenIds(" " separated)`} value={makeBundleState.tokenIds} onChange={e => { updateMakeBundleStateKV("tokenIds", e.target.value) }} />

                        <Form.Button
                            content="Make Bundle!"
                            onClick={createBundle}
                        />

                        <Message error color="red">
                            {makeBundleState.error}
                        </Message>

                        <Message success color="blue" onDismiss={() => updateMakeBundleState({ success: false })}>
                            {makeBundleState.success}
                        </Message>

                    </Form>
                </Segment>
            ) : null}



        </Grid.Column>


    )

}

export default MakeBundle;