import { Grid, Header } from "semantic-ui-react";

function Footer() {

    return (
        <Grid columns={2} textAlign="center">

            <Grid.Column>
                <a href="tos.pdf" target="_blank" rel="no-referrer">
                    <Header as='h5' className="secondaryFont mockLinkText">Terms And Conditions</Header>
                </a>
            </Grid.Column>

            <Grid.Column>
                <a href="privacyPolicy.pdf" target="_blank" rel="no-referrer">
                    <Header as='h5' className="secondaryFont mockLinkText">Privacy Policy</Header>
                </a>
            </Grid.Column>

        </Grid>
    )

}

export default Footer;