import React from 'react';
import { Header, Grid, Image, Card, Table, Button } from 'semantic-ui-react';
import ImageModal from './ImageModal';
import AppContext, { getMockBundleDataState } from '../context/AppContext';
import lstyle from './BundleItems.module.scss';

function BundleBlock() {

    const [descShow, setDescShow] = React.useState({
        1: false,
        2: false,
        3: false,
        4: false,
    }) // No 0, 0 is poster index.

    const appContext = React.useContext(AppContext);
    const [bundleData, setBundleData] = getMockBundleDataState(appContext); //eslint-disable-line

    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalImgSrc, setmodalImgSrc] = React.useState(false);
    const closeModal = () => setModalOpen(false);

    const openModalImg = (src) => {
        setmodalImgSrc(src);
        setModalOpen(true);
    }

    const renderAttributes = () => {
        let rows = bundleData.tokenMeta[0].attributes.map(attrib => {
            if (attrib.key === "twitter") {
                return null;
            }
            else {
                return (<>
                    <Table.Row>
                        <Table.Cell>{attrib.key}</Table.Cell>
                        <Table.Cell>{attrib.value}</Table.Cell>
                    </Table.Row>
                </>)
            }
        }
        )
        return (
            <Table basic="very" compact="very" className="secondaryFont" style={{ fontSize: "11px" }}>
                <Table.Body>
                    {rows.length >= 1 && (rows.length !== 1 && rows[0] !== null) ? rows : (
                        <Table.Row>
                            <Table.Cell>No Attributes</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        )
    }

    const genItemCols = () => {

        let cols = bundleData.bundleImgs.map((img, i) => {

            const getTwitterUrl = () => {
                let twitAttrib = bundleData.tokenMeta[i + 1].attributes.filter(k => (k.key === "twitter"));
                if (twitAttrib[0]) {
                    return "https://twitter.com/" + twitAttrib[i].value;
                }
                else {
                    return false;
                }
            }

            const getCardDesc = (metaIndex) => {
                
                
                if (!!bundleData.tokenMeta[i + 1].description) {
                
                const description = descShow[metaIndex] ? bundleData.tokenMeta[metaIndex].description : (bundleData.tokenMeta[metaIndex].description.slice(0,44) + ". . ."); 
                
                return (<>
                        {description}
                        <Button content={!!descShow[metaIndex] ? "Hide" : "Read More" } size="mini" style={{display:"block"}} className="mt-1" onClick={ () => setDescShow(s => ({...s, [metaIndex]: !s[metaIndex]}))}/>
                    </>)    
                }
                else {
                    return ""
                }
            }

            return (
                <Grid.Column computer={4} tablet={5} mobile={8} key={img} verticalAlign="top">
                    <div className="cEmbedWrap">
                        <Card raised>
                            <Image src={img} className={[lstyle.itemCol, "image-box-styling"].join(" ")} onClick={() => openModalImg(img)}  />
                            <Card.Content>
                                <Card.Header textAlign="left" style={{ fontSize: "12px" }}>{bundleData.tokenMeta[i + 1].name.slice(0, 34) + (bundleData.tokenMeta[i + 1].name.length > 34 ? "..." : "")}</Card.Header>
                                <Card.Meta textAlign="left" style={{ fontSize: "11px" }}>
                                    {getTwitterUrl() ? <a href={getTwitterUrl()}>ArtistLink</a> : ""}
                                </Card.Meta>
                                <Card.Description textAlign="left">
                                    {getCardDesc(i+1)}
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                {renderAttributes()}
                            </Card.Content>
                        </Card>
                    </div>
                </Grid.Column>
            )
        })
        return cols
    }

    if (!bundleData) {
        return <Header sub className="mt-3">Please check back later for a new bundle.</Header>
    }

    return (<>

        <ImageModal isOpen={modalOpen} src={modalImgSrc} closeFx={closeModal} />

        <Grid stretched >
            {genItemCols()}
        </Grid>

    </>)

}

export default BundleBlock;