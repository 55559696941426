import React from 'react';

// Export default context
export const defaultContext = {
    bundleData: false,
};

const AppContext = React.createContext(defaultContext);

/* State Management Functions */

/* Data Converters */
export const bundleIdToState = (id) => {}

/* Mock State Helpers */
export const getMockBundleDataState = (context) => {
    let setter = val => context.setState(state => ({...state, bundleData: val}));
    return [context.state.bundleData, setter]
}

export default AppContext;