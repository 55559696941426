import React from 'react';
import { Grid, Segment, Header, Form } from 'semantic-ui-react'
import ethHandler from '../../eth/ethHandler';

function CheckBalance() {

    const [open, setOpen] = React.useState(true);
    const [checking, setChecking] = React.useState(false);
    const [formState, setFormState] = React.useState({
        address: "",
        tokenID: "503911",
        polledBal: "?",
    })
    const kvUpdater = (k, v) => setFormState((s) => ({ ...s, [k]: v }))

    // Get user address as default
    React.useEffect(() => {
        const getA = async () => {
            // let a = await ethHandler.getAccount();
            kvUpdater("address", "0x4A679253410272dd5232B3Ff7cF5dbB88f295319");
        }
        getA();
    }, [])


    const checkBalance = async () => {
        console.log(formState);
        setChecking(true);
        kvUpdater("polledBal", ". . .")
        let bal = await ethHandler.getRari1155Bal(formState.address, formState.tokenID);
        setTimeout( () => {
            kvUpdater("polledBal", bal);
            setChecking(false);
        }, 750)
    }

    return (
        <Grid.Column width={16}>
            <Segment onClick={() => setOpen(s => !s)} className="hover-accent">
                <Header>
                    Check Token Balance
                    <Header.Subheader>
                        Check balance of token id for address on Rari Contract
                    </Header.Subheader>
                </Header>
            </Segment>

            {open ? (

                <Segment className="mb-0 mt-0">

                    <Form>

                        <Form.Input
                            label="Address"
                            value={formState.address}
                            onChange={(e) => kvUpdater("address", e.target.value)}
                        />

                        <Form.Input
                            label="tokenID"
                            value={formState.tokenID}
                            onChange={(e) => kvUpdater("tokenID", e.target.value)}
                        />

                        <Form.Group>
                            <Form.Input  action={{content:"Check", onClick: checkBalance, loading: checking}} value={formState.polledBal} actionPosition="left"/>
                        </Form.Group>

                    </Form>



                </Segment>

            ) : null}

        </Grid.Column>
    )

}

export default CheckBalance;