import React from 'react';
import { Modal, Image } from 'semantic-ui-react';
import lstyle from './ImageModal.module.scss';

/**
 * @prop {string} src - Image Source
 * @prop {bool} isOpen - Is the modal open
 * @prop {function} closeFx - Close Modal Function 
 * @prop {function} openFx - Open Modal Function
 * @returns 
 */
function ImageModal({ src, isOpen, closeFx, openFx }) {

    return (

        <Modal
            closeIcon
            dimmer="blurring"
            onClose={closeFx}
            onOpen={openFx}
            open={isOpen}
            className={lstyle.modal}
        >

            <Modal.Content>
                <Image src={src} centered />
            </Modal.Content>

        </Modal>

    )

}

export default ImageModal;