import { Grid, Header } from 'semantic-ui-react';

function Logo() {

    return (

        <Grid>

            <Grid.Column width={8} verticalAlign="middle" textAlign="left">
                <Header as="h3" className="secondaryFont uppercase">
                    Incoherents Club
                    <Header.Subheader className="primaryFont uppercase" style={{ marginTop: ".5rem" }}>
                        Pay what you want NFT bundles
                    </Header.Subheader>
                </Header>
            </Grid.Column>

            <Grid.Column width={8} textAlign="center" verticalAlign="middle">
                <a href="https://incoherentsclub.substack.com/p/about" target="_blank" rel="noreferrer">
                    <Header as="h3" className="primaryFont mockLinkText">ABOUT</Header>
                </a>
            </Grid.Column>

        </Grid>


    )

}

export default Logo;