import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Header, Segment, Button } from 'semantic-ui-react';
import ConnectPrompt from '../components/ConnectPrompt';
import ethHandler from '../eth/ethHandler';
import lstyle from './Admin.module.scss';
import MakeBundle from './AdminComponents/MakeBundle';
import TestFork from './AdminComponents/TestFork';
import Notes from './AdminComponents/Notes';
import CheckBalance from './AdminComponents/CheckBalance';

export default function Admin() {

    const [isConnected, setConnected] = React.useState(ethHandler.isConnected());
    const [isOwner, setIsOwner] = React.useState(false);
    const setConnectedTrue = () => { setConnected(true) };

    React.useEffect(() => {

        const checkOwner = async () => {
            let isOwn = await ethHandler.checkIfOwner();
            setIsOwner(isOwn);
        }

        checkOwner();

    }, [])

    return (

        <Grid container padded>

            <div className={lstyle.backButton}>
                <Button color="black" content="BACK" size="mini" to="/" as={Link} />
            </div>

            <Grid.Column width={16}>
                <Header>
                    Admin Panel
                    <Header.Subheader>
                        All calls must be made by the contract owner
                    </Header.Subheader>
                </Header>
            </Grid.Column>

            <Grid.Column width={16}>
                <Notes/>
            </Grid.Column>

            <Grid.Column width={16}>
                <TestFork/>
            </Grid.Column>

            { isConnected ?
                <AdminPanel isOwner={isOwner} />
                : <ConnectPrompt setIsOwner={setIsOwner} setConnectedTrue={setConnectedTrue} />
            }

        </Grid>

    )

}

function AdminPanel({ isOwner }) {

    return !isOwner ? (

        <Grid.Column width={16}>
            <Segment color="red">
                <Header sub color="red">
                    Sorry, you are not the contract owner.
                    </Header>
            </Segment>
        </Grid.Column>

    ) : (<>

        <CheckBalance/>
        <MakeBundle />

    </>)

}

/*

address to, uint256 id, uint256 value, bytes memory data

*/