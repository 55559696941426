import { isProduction } from '../util/generic';

export const CONTRACTNAMES = {
    INCOHERENTS: "INCOHERENTS",
    MOCKER: "MOCKER",
    RARI_1155: "RARI_1155"
}

/* Takes a CONTRACTNAME enum and returns the corresponding Contract Address*/
export const contractNameToContractAddress = (cName) => {

    let isProd = isProduction();

    let address = (() => {
        switch (cName) {
            case CONTRACTNAMES.INCOHERENTS: return isProd ?
                process.env.REACT_APP_CONTRACT_INCOHERENTS
                : process.env.REACT_APP_CONTRACT_INCOHERENTS_DEV;
            case CONTRACTNAMES.MOCKER: return isProd ?
                process.env.REACT_APP_CONTRACT_MOCKERC1155
                : process.env.REACT_APP_CONTRACT_MOCKERC1155_DEV;
            case CONTRACTNAMES.RARI_1155: return process.env.REACT_APP_CONTRACT_RARIBLE_1155;
            default: console.warn("Invalid contract requested"); break;
        }
    })()

    if (!address) {
        console.warn("Address is null for " + cName + " Environment may be empty, verify .env is setup properly!")
    }

    return address;

}